.product{
    width: 100%;
    margin: 100px 0;
}
.product-head{
    position: relative;
    display: flex;
    justify-content: space-evenly;
}
.product-head>h1{
    font-style: normal;
    font-weight: 700;
    text-align: center;
    font-size: 30px;
    line-height: 45px;
    margin: 0 20px ;
}
.product-head > img{
    position: absolute;
    left: 530px;
    top: 19px;
    z-index: -1;
}
.products{
    width: 90%;
    margin: 50px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.our{
    width: 30%;
    margin: 20px 0;
    background: #F3F5FF;
    border-radius: 20px;
    padding: 10px;
}
.our > img{
    display: block;
    /* margin: 0 0 0 120px; */
    margin: 0 auto;
    padding: 10px;
}
.our > span{
    text-align: center;
    text-transform: capitalize;
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    line-height: 38px;
}

@media only screen and (max-width:1200px) {
    .product{
        width: 100%;
        margin: 100px 0;
    }
    .product-head{
        position: relative;
        display: flex;
        justify-content: space-evenly;
    }
    .product-head>h1{
        font-style: normal;
        font-weight: 700;
        text-align: center;
        font-size: 30px;
        line-height: 45px;
        margin: 0 20px ;
        padding: 10px 15px;
        background: #F3F5FF;
        border-radius: 20px;
    }
    .product-head > img{
        position: absolute;
        left: 420px;
        top: 19px;
        display: none;
        z-index: -1;
    }
    .products{
        width: 90%;
        margin: 50px auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
    .our{
        width: 30%;
        margin: 20px;
        background: #F3F5FF;
        border-radius: 20px;
        padding: 10px;
    }
    .our > img{
        display: block;
        margin: 0 auto;
        padding: 10px;
    }
    .our > span{
        text-align: center;
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
    text-transform: capitalize;
    line-height: 38px;
    }
}
@media only screen and (max-width:992px) {
    .product{
        width: 100%;
        margin: 100px 0;
    }
    .product-head{
        position: relative;
        display: flex;
    justify-content: space-evenly;
    }
    .product-head >h1{
        font-style: normal;
        font-weight: 700;
        padding: 10px 15px;
        background: #F3F5FF;
        text-align: center;
        font-size: 30px;
        line-height: 45px;
        margin: 0 20px ;
    }
    .product-head> img{
        position: absolute;
        left: 300px;
        top: 19px;
        z-index: -1;
        display: none;

    }
    .products{
        width: 90%;
        margin: 50px auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
    .our{
        width: 30%;
        margin: 20px;
        background: #F3F5FF;
        border-radius: 20px;
        padding: 10px;
    }
    .our > img{
        display: block;
        margin: 0 auto;
        padding: 10px;
    }
    .our > span{
        text-align: center;
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
    text-transform: capitalize;
    line-height: 38px;
    }
}
@media only screen and (max-width:768px) {
    .product{
        width: 100%;
        margin: 100px 0;
    }
    .product-head{
        position: relative;
        display: flex;
    justify-content: space-evenly;
    }
    .product-head > h1{
        font-style: normal;
        font-weight: 700;
        text-align: center;
        font-size: 30px;
        padding: 10px 15px;
        background: #F3F5FF;
        line-height: 45px;
        margin: 0 20px ;
    }
    .product-head > img{
        position: absolute;
        left: 85px;
        top: 19px;
        display: none;
        z-index: -1;
    }
    .products{
        width: 90%;
        margin: 50px auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
    .our{
        width: 60%;
        margin: 20 40px;
        background: #F3F5FF;
        border-radius: 20px;
        padding: 10px;
    }
    .our > img{
        display: block;
        margin: 0 auto;
        padding: 10px;
    }
    .our > span{
        text-align: center;
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        text-transform: capitalize;
        line-height: 38px;
    }
}
