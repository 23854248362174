*{
    margin: 0;
    padding: 0;
    font-family: 'Poppins';
}
.busy{
    width: 100%;
    display: flex;
    flex-wrap:wrap;
    position: relative;
    padding-bottom: 50px;
}
.busyleft{
    width: 50%;
    box-sizing: border-box;
    padding: 30px;
    margin: 80px 0 0 0 ;
}
.vector{
width: 100%;
height: 100%;

position: absolute;
z-index: -1;
}
.bee-h{
    margin: 10px 0;
    color: #E8B349;
}
.bee{
    width: 30px;    
    margin: -10px 0;
}
.grow{
    margin: 5px 0;
}
.busy1{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 17px;
line-height: 30px;
margin: 10px 0;
}
.busy2{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 17px;
line-height: 30px;
margin: 15px 0;
}
.busybtn{
display: inline-block;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 30px;
background: #FF7878;
border-radius: 10px;
color: #ffffff;
padding: 8px;
margin: 40px 0;
text-decoration: none;
}
.busyright{
    width: 50%;
    box-sizing: border-box;
    padding: 30px;
    margin: 40px 0 0 0 ;
}
.busyright > img{
    width: 100%;
}
 @media only screen and (max-width:1200px) {
    .busy{
        width: 100%;
        display: flex;
        flex-wrap:wrap;
        position: relative;
    overflow-x: hidden;

    }
    .busyleft{
        width: 50%;
        box-sizing: border-box;
        padding: 30px;
        margin-top: 80px;
    }
    .vector{
    width: 100%;
    position: absolute;
    z-index: -1;
    }
    .bee-h{
        margin: 10px 0;
        color: #E8B349;
    }
    .bee{
        width: 30px;    
    }
    .grow{
        margin: 5px 0;
    }
    .busy1{
        font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    margin: 10px 0;
    }
    .busy2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    margin: 15px 0;
    }
    .busybtn{
    display: inline-block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    background: #FF7878;
    border-radius: 16px;
    padding: 8px;
    margin: 40px 0;
    text-decoration: none;

    }
    .busyright{
        width: 50%;
        box-sizing: border-box;
        padding: 30px;
        margin: 40px 0 0 0 ;
    }
    .busyright > img{
        width: 100%;
    }
    
}
 @media only screen and (max-width:992px) {
    .busy{
        width: 100%;
        display: flex;
        flex-wrap:wrap;
        position: relative;
        flex-direction: row-reverse;
    overflow-x: hidden;

    }
    .busyleft{
        width: 100%;
        box-sizing: border-box;
        padding: 30px;
        margin: 80px 0 0 0 ;
    }
    .vector{
    width: 100%;
    position: absolute;
    z-index: -1;
    }
    .bee-h{
        margin: 10px 0;
        color: #E8B349;
    }
    .bee{
        width: 30px;    
    }
    .grow{
        margin: 5px 0;
    }
    .busy1{
        font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    margin: 10px 0;
    }
    .busy2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    margin: 15px 0;
    }
    .busybtn{
    display: inline-block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    background: #FF7878;
    border-radius: 16px;
    padding: 8px;
    margin: 20px 0;
    text-decoration: none;

    }
    .busyright{
        width: 100%;
        box-sizing: border-box;
        /* padding: 30px; */
        margin: 40px auto 0 auto ;
    }
    .busyright > img{
        width: 70%;
        display: block;
        margin: 0 auto;
        text-align: center;
    }
    
}
@media only screen and (max-width:768px){
    .busy{
        width: 100%;
        display: flex;
        flex-wrap:wrap;
        position: relative;
        flex-direction: row-reverse;
    overflow-x: hidden;

        
    }
    .busyleft{
        width: 100%;
        box-sizing: border-box;
        /* padding: 30px; */
        margin: 80px 0 0 0 ;
    }
    .vector{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    }
    .bee-h{
        margin: 10px 0;
        color: #E8B349;
    }
    .bee{
        width: 30px;    
    }
    .grow{
        margin: 5px 0;
    }
    .busy1{
        font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    margin: 10px 0;
    }
    .busy2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    margin: 15px 0;
    }
    .busybtn{
    display: inline-block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    background: #FF7878;
    border-radius: 16px;
    padding: 8px;
    margin: 40px 0;
    text-decoration: none;
    }
    .busyright{
        width: 100%;
        box-sizing: border-box;
        padding: 30px;
        margin: 40px 0 0 0 ;
    }
    .busyright > img{
        width: 100%;
    }
    
} 