#contact {
  width: 100%;
  text-align: center;
  padding: 20px 0;
  background-color: rgba(231, 180, 71, 0.5);
}
.con-h {
  text-align: center;
  margin: 10px 0;
}
.contacts {
  margin: 35px auto;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.contact1 {
  width: 30%;
}
.contact1 > div {
  margin: 0 auto;
  width: 50px;
  padding: 21px 18px;
  background-color: #fff;
  opacity: 0.4;
  border-radius: 100px;
}
.contact1 > img,
.contact1 > span {
  display: inline-block;
  text-align: center;
  margin: 10px auto;
}
.con-img {
  margin: 0 auto;
}
.con3 {
  padding: 21px 18px !important;
}
@media only screen and (max-width: 1200px) {
  #contact {
    width: 100%;
    text-align: center;
    padding: 20px 0;
    background-color: rgba(231, 180, 71, 0.5);
  }
  .con-h {
    text-align: center;
  }
  .contacts {
    margin: 35px auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .contact1 {
    width: 30%;
  }
  .contact1 > div {
    margin: 0 auto;
    width: 50px;
    padding: 21px 18px;
    background-color: #fff;
    opacity: 0.4;
    border-radius: 100px;
  }
  .contact1 > img,
  .contact1 > span {
    display: inline-block;
    text-align: center;
    margin: 10px auto;
  }
  .con-img {
    background-color: aqua;
  }
  .con3 {
    padding: 21px 18px !important;
  }
}
@media only screen and (max-width: 992px) {
  #contact {
    width: 100%;
    text-align: center;
    padding: 20px 0;
    background-color: rgba(231, 180, 71, 0.5);
  }
  .con-h {
    text-align: center;
  }
  .contacts {
    margin: 35px auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .contact1 {
    width: 50%;
    margin: 30px 0;
  }
  .contact1 > div {
    margin: 0 auto;
    width: 50px;
    padding: 21px 18px;
    background-color: #fff;
    opacity: 0.4;
    border-radius: 100px;
  }
  .contact1 > img,
  .contact1 > span {
    display: inline-block;
    text-align: center;
    margin: 10px auto;
  }
  .con3 {
    padding: 21px 18px !important;
  }
}
@media only screen and (max-width: 768px) {
  #contact {
    width: 100%;
    text-align: center;
    padding: 20px 0;
    background-color: rgba(231, 180, 71, 0.5);
  }
  .con-h {
    text-align: center;
  }
  .contacts {
    margin: 35px auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .contact1 {
    width: 100%;
    margin: 30px 0;
  }
  .contact1 > div {
    margin: 0 auto;
    width: 50px;
    padding: 21px 18px;
    background-color: #fff;
    opacity: 0.4;
    border-radius: 100px;
  }
  .contact1 > img,
  .contact1 > span {
    display: inline-block;
    text-align: center;
    margin: 10px auto;
  }

  .con3 {
    padding: 21px 18px !important;
  }
}
@font-face{
  font-family: ethnocentric;
  src: url('/home/fe/Desktop/bee/src/fonts/ethnocentric rg.otf');
}
.zustpe {
  color: #115e8a;
  font-family: ethnocentric;
  font-size: 14px;
}
