.design{
    width: 100%;
    margin: 50px 0;
    /* overflow-x: hidden; */
}
.tech-head{
    position: relative;
    display: flex;
    justify-content: space-evenly;
}
.tech-head> h1{
    text-align: center;
}
.tech-head > img{
    position: absolute;
    left: 440px;
    top: 20px;
    z-index: -1;
}

.design1,.develop{
    width: 90%;
    /* margin: 50px 0; */
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
}
.designleft,.developright{
    width: 50%;
}
.designleft > img ,.developright > img{
    width: 100%;
    box-sizing: border-box;
    padding: 75px;
    /* margin: 0 auto; */
}
.designright,.developleft{
    width: 50%;
    margin: -100px 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: justify;
    box-sizing: border-box;
    padding: 50px;
}
.designright > h1,.developleft > h1{
    text-align: center;
    margin: 15px 0;
}
.designright > p,.developleft > p{
    margin: 10px 0;
}
.design_arrow,.develop_arrow{
    position: absolute;
}
.design_arrow{
    width: 250px;
    right: 410px;
    top: 140px;
}
.develop_arrow{
    left: 410px;
    width: 250px;
    top: 150px;
}
@media only screen and (max-width:1200px) {
    .design{
        width: 100%;
        margin: 50px 0;
    }
    .tech-head{
    position: relative;
    display: flex;
    justify-content: space-evenly;
    }
    .tech-head> h1{
        text-align: center;
        padding: 10px 15px;
        border-radius: 20px;
        background-color: rgb(213, 213, 213,.20)
    }
    .tech-head > img{
        position: absolute;
        left: 350px;
        top: 20px;
        display: none;
        z-index: -1;
    }
    
    .design1,.develop{
        width: 90%;
        /* margin: 50px 0; */
        position: relative;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
    }
    .designleft,.developright{
        width: 50%;
    }
    .designleft > img ,.developright > img{
        width: 100%;
        box-sizing: border-box;
        padding: 75px;
        /* margin: 0 auto; */
    }
    .designright,.developleft{
        width: 50%;
        margin: -100px 0;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        text-align: justify;
        box-sizing: border-box;
        padding: 50px;
    }
    .designright > h1,.developleft > h1{
        text-align: center;
        margin: 15px 0;
    }
    .designright > p,.developleft > p{
        margin: 10px 0;
    }
    .design_arrow,.develop_arrow{
        position: absolute;
    }
    .design_arrow{
        width: 150px;
        right: 350px;
        top: 120px;   
    }
    .develop_arrow{
        width: 150px;
        right: 350px;
        top: 120px;
    }    
}
@media only screen and (max-width:992px) {
    .design{
        width: 100%;
        margin: 50px 0;
    }
    .tech-head{
        position: relative;
        display: flex;
        justify-content: space-evenly;
        }
    .tech-head > h1{
        text-align: center;
        padding: 10px 15px;
        border-radius: 20px;
        background-color: rgb(213, 213, 213,.20)
    }
    .tech-head > img{
        position: absolute;
        left: 230px;
        top: 20px;
        z-index: -1;
        display: none;

    }
    
    .design1,.develop{
        width: 90%;
        /* margin: 50px 0; */
        position: relative;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
    }
    .designleft,.developright{
        width: 50%;
    }
    .designleft > img ,.developright > img{
        width: 100%;
        box-sizing: border-box;
        padding: 75px;
        /* margin: 0 auto; */
    }
    .designright,.developleft{
        width: 50%;
        margin: -100px 0;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        text-align: justify;
        box-sizing: border-box;
        padding: 50px;
    }
    .designright > h1,.developleft > h1{
        text-align: center;
        font-size:30px;
        margin: 15px 0;
    }
    .designright > p,.developleft > p{
        margin: 10px 0;
        font-size: 16px;
    }
    .design_arrow,.develop_arrow{
        position: absolute;
    }
    .design_arrow{
        width: 150px;
        right: 300px;
        top: 90px;
    }
    .develop_arrow{
        width: 150px;
        left: 300px;
        top: 77px;
    }    
}
@media only screen and (max-width:768px) {
    .design{
        width: 100%;
        margin: 50px 0;
    }
    .tech-head{
        position: relative;
        display: flex;
        justify-content: space-evenly;
        }
    .tech-head > h1{
        text-align: center;
        position: relative;
        padding: 10px 15px;
        border-radius: 20px;
        background-color: rgb(213, 213, 213,.20)
    }
    .tech-head > img{
        position: absolute;
        left: 80px;
        top: 20px;
        display: none;
        z-index: -1;
    }
    
    .design1,.develop{
        width: 90%;
        position: relative;
        margin: 50px auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
    }
    .develop{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    .designleft,.developright{
        width: 100%;
    }
    .designleft > img ,.developright > img{
        width: 80%;
        /* box-sizing: border-box; */
        display: block;
        margin: 0 auto;
        /* padding: 75px; */
    }
    .designright,.developleft{
        width: 100%;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        text-align: justify;
        box-sizing: border-box;
        padding: 50px;
    }
    .designright > h1,.developleft > h1{
        text-align: center;
        margin: 15px 0;
    }
    .designright > p,.developleft > p{
        margin: 10px 0;
    }
    .design_arrow,.develop_arrow{
        position: absolute;
        display: none;
    }
    .design_arrow{
        width: 250px;
        right: 410px;
        top: 140px;
    }
    .develop_arrow{
        left: 410px;
        width: 250px;
        top: 160px;
    }    
}