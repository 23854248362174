.ourservice{
width: 100%;
margin: 50px 0;
}
.service-head{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    position: relative;
}
.service-head > h1 {
    font-weight: 700;
    text-align: center;
    font-size: 30px;
    line-height: 45px;
    margin-left:20px;
    /* border-radius: 20px;
    padding: 10px 15px;
    background: #FFFAF3; */
    position: relative;
}
.service-head  > img{
    position: absolute;
    left: 550px;
    /* display: none; */
    top: 20px;
    z-index: -1;
}
.service{
    width: 90%;
    margin: 50px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

}
.services{
    width: 30%;
    margin: 20px 0;
    background: #FFFAF3;
    border-radius: 20px;
    padding: 10px;
}
.services > img {
    display: block;
    margin:10px auto;
}
.services > span{
    display: block;
    text-align: center;
    margin: 10px 0;
}
@media only screen and (max-width:1200px) {
    .ourservice{
        width: 100%;
        margin: 50px 0;
        }
        .service-head{
            display: flex;
            width: 100%;
            justify-content: space-evenly;
            position: relative;
        }
        .service-head > h1 {
            font-weight: 700;
            text-align: center;
            font-size: 30px;
            padding: 10px 15px;
            border-radius: 20px;
            background: #FFFAF3;
            line-height: 45px;
            margin-left:20px;
            position: relative;
        }
        .service-head  > img{
            position: absolute;
            display: none;
            left: 420px;
            top: 20px;
            z-index: -1;
        }
        .service{
            width: 90%;
            margin: 50px auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
        
        }
        .services{
            width: 29%;
            margin: 20px 0;
            background: #FFFAF3;
            border-radius: 20px;
            padding: 20px;
        }
        .services > img {
            display: block;
            margin:10px auto;
        }
        .services > span{
            display: block;
            text-align: center;
            margin: 10px 0;
        }   
}
@media only screen and (max-width:992px) {
    .ourservice{
        width: 100%;
        margin: 50px 0;
    }
    .service-head{
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        position: relative;
    }
        .service-head > h1 {
            font-weight: 700;
            text-align: center;
            font-size: 30px;
            border-radius:20px ;
            line-height: 45px;
            margin-left:20px;    
            padding: 10px 15px;
            background: #FFFAF3;
            position: relative;
        }
        .service-head  > img{
            position: absolute;
            left: 300px;
            top: 20px;
            display: none;
            z-index: -1;
        }
        .service{
            width: 90%;
            margin: 50px auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
        
        }
        .services{
            width: 29%;
            margin: 20px 0;
            background: #FFFAF3;
            border-radius: 20px;
            padding: 20px;
        }
        .services > img {
            display: block;
            margin:10px auto;
        }
        .services > span{
            display: block;
            text-align: center;
            margin: 10px 0;
        }   
}
@media only screen and (max-width:768px) {
    .ourservice{
        width: 100%;
        margin: 50px 0;
    }
    .service-head{
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        position: relative;
    }
        .service-head > h1 {
            font-weight: 700;
            text-align: center;
            font-size: 30px;
            padding: 10px 15px;
            background: #FFFAF3;
            border-radius: 20px;
            line-height: 45px;
            margin-left:20px;
            position: relative;
        }
        .service-head > img{
            position: absolute;
            left: 100px;
            top: 20px;
            z-index: -1;
            display: none;
        }
        .service{
            width: 90%;
            margin: 50px auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
        
        }
        .services{
            width: 50%;
            margin: 20px 0;
            background: #FFFAF3;
            border-radius: 20px;
            padding: 20px;
        }
        .services > img {
            display: block;
            margin:10px auto;
        }
        .services > span{
            display: block;
            text-align: center;
            margin: 10px 0;
        }   
}